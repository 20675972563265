import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageSection, LoadingCard } from 'adc-ui-components';

import { setSubmit, setSuccess, setError } from '../../actions/userMessages';
import { getAutopay } from '../../actions/autopay';
import { submitPayment } from '../../actions/payment';

import { txnDataPaymentEnabled } from '../../helpers/adobeFlags';
import { joinClass } from '../../helpers/component';
import { kibanaLog } from '../../helpers/logger';
import { scrollToInvalid, getErrorFromResponse } from '../../helpers/errors';
import paymentMock from '../../helpers/paymentMock';
import {
  NEW_PAYMENT_CONFIRMATION,
  NEW_PAYMENT, AUTHENTICATION_REQUIRED,
} from '../../helpers/routes';
import { getIsDisconnected } from '../../helpers/account';
import { getIsXapCurrent, getIsXapExitInProgress } from '../../helpers/xap';

import CardGroup from '../../components/CardGroup';
import PaymentSummary from '../../components/PaymentSummary';
import PaymentMessage from '../../components/PaymentMessage';
import P2PReviewNote from '../../components/p2p/P2PReviewNote';
import P2PReviewMessage from '../../components/p2p/P2PReviewMessage';
import PageTitle from '../../components/PageTitle';
import AutopayCheckbox from '../../components/AutopayCheckbox';
import Jump from '../Jump';
import AccountInfoPushdown from './AccountInfoPushdown';
import { getTransactionMetadata } from '../../helpers/payments';

class PaymentReview extends Component {
  constructor() {
    super();

    this.state = {
      autopay: false,
      pageLoadedTime: Date.now(),
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const {
      review,
      history,
      showAutoPay,
      bill,
    } = this.props;

    // if no review data go back to payment
    if (!review) {
      const mock = paymentMock(false);
      if (mock) {
        const { form, bill: mockBill } = mock;
        Object.assign(bill, mockBill);
        this.setState({ showAutoPay: true, review: form });
        return;
      }
      history.push(NEW_PAYMENT);
    }

    // put this in the state because the enable autopay
    // call will complete before payment submit, and makes
    // updates the global store
    this.setState({ showAutoPay });
  }

  onFormSubmit(e) {
    const {
      handlePaymentSubmit,
      history,
      handleSetSubmit,
      transactionMetadata,
    } = this.props;
    const { autopay } = this.state;

    e.preventDefault();

    handleSetSubmit();

    kibanaLog('payment_form_submit', {
      form: 'NewPayment/Review',
    });

    handlePaymentSubmit({ autopay, transactionMetadata }).then(() => {
      history.push(NEW_PAYMENT_CONFIRMATION);
    }).catch(this.onError);
  }

  onSubmitClick(e) {
    const { transactionMetadata: { deviceFingerprintId } } = this.props;
    const { pageLoadedTime } = this.state;

    const msSincePageLoad = Date.now() - pageLoadedTime;
    // takes around 6-8 seconds total from when the page loaded to receive the data
    const timer = 8000 - msSincePageLoad;
    const waitForTransactionData = () => new Promise(resolve => setTimeout(resolve, timer));

    e.preventDefault();

    // user tries to click submit before we received the deviceFingerprintId from CPC
    if (!deviceFingerprintId) {
      kibanaLog('Clicked submit before receiving CPC enhanced transaction data', { secondsSincePageLoad: msSincePageLoad / 1000 });
      waitForTransactionData().then(() => {
        this.onFormSubmit(e);
      });
    } else {
      this.onFormSubmit(e);
    }
  }

  onError(err = {}) {
    const {
      history,
      handleSetError,
      isLite,
    } = this.props;
    const hasConvoyMessage = !!(err.data && err.data.code && err.data.message);
    const error = getErrorFromResponse(err);
    const isAuthPaymentError = (
      error.code === 'CON500.PAYMENT.50.52'
      || error.code === 'CON500.PAYMENT.50.53'
      || error.code === 'PAYMENT-5052'
      || error.code === 'PAYMENT-5052'
    );
    try {
      if (error.message && (!isAuthPaymentError)) {
        error.internal = {
          text: error.message,
          showModal: !error.isMapped && !hasConvoyMessage,
        };
      }
      throw error;
    } catch (thrown) {
      if (!('internal' in thrown)) {
        thrown.internal = {
          text: error.message || 'Unknown error occurred',
          showModal: false,
        };
      }
      scrollToInvalid();
      if (isLite && isAuthPaymentError) {
        history.push(AUTHENTICATION_REQUIRED);
      } else {
        history.push(NEW_PAYMENT);
        handleSetError(thrown.internal);
        throw thrown;
      }
    }
  }

  render() {
    const {
      accountNumber,
      autopay,
      loading,
      submitting,
      history,
      account,
      bill,
      isHarness,
      review: propsReview,
      showP2PNote,
      selectedAccountAlias,
      serviceAddress,
      hasDeviceFingerprintId,
    } = this.props;
    const {
      review: stateReview,
    } = this.state;

    const review = propsReview || stateReview;

    const { autopay: autopayVal, showAutoPay } = this.state;

    if (!review) return null;
    const paymentMessage = P2PReviewMessage({
      children: [PaymentMessage({ bill, payment: review, type: 'review' })],
      account,
      bill,
      review,
    });
    const p2pReviewNote = showP2PNote && P2PReviewNote({ bill, review });

    return (
      <>
        {txnDataPaymentEnabled && !hasDeviceFingerprintId && (
          // This loads CPC's "standalone" component - there is no UI
          <Jump cpcPageType="DeviceFingerPrintId" />
        )}
        <AccountInfoPushdown
          accountNumber={accountNumber}
          selectedAccountAlias={selectedAccountAlias}
          serviceAddress={serviceAddress}
        />
        <PageSection>
          <form
            onSubmit={txnDataPaymentEnabled ? this.onSubmitClick : this.onFormSubmit}
            noValidate
          >
            <PageTitle>Review your payment</PageTitle>

            <div className="payment-section">
              <PaymentSummary summary={review} />
              {(paymentMessage || p2pReviewNote) && (
                <CardGroup>
                  {paymentMessage}
                  {p2pReviewNote}
                </CardGroup>
              )}
            </div>

            {loading && <LoadingCard />}

            {!loading && showAutoPay && (
              <AutopayCheckbox
                autopayVal={autopayVal}
                handleSetAutopayState={() => this.setState({ autopay: !autopayVal })}
                autopay={autopay}
                review={review}
              />
            )}

            <div className="action action--right pt6">
              <div className="action__item">
                <button className={joinClass('button button--primary', submitting && 'is-loading')} disabled={submitting} type="submit">Submit Payment</button>
              </div>
              {!isHarness && (
                <div className="action__item">
                  <button className="button" type="button" onClick={() => history.goBack()}>
                    Back
                  </button>
                </div>
              )}
            </div>
          </form>
        </PageSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    auth: {
      macaroon: {
        lite: isLite,
        accounts = [],
      } = {},
    },
    autopay: {
      autopay,
      loading: autopayLoading,
    },
    account: {
      account,
      account: {
        accountNumber,
        serviceAddress = {},
      } = {},
    },
    bill: {
      bill,
      bill: {
        summary: {
          autoPayEnabled,
          balanceDue,
        } = {},
      } = {},
    },
    cpc: { enhancedTransactionData = {} },
    payment: { review, submitting },
    harness: { isHarness },
  } = state;

  const isDisconnected = getIsDisconnected(account.status);

  const transactionMetadata = getTransactionMetadata({ balanceDue, enhancedTransactionData });

  return {
    accountNumber,
    isLite,
    autopay,
    account,
    bill,
    review,
    loading: !isLite && autopayLoading,
    submitting,
    isHarness,
    showAutoPay: (
      !autoPayEnabled
      && !isLite
      && !isDisconnected
      && !review?.walletId // walletId is null if storePayment is selected on the form
    ),
    showP2PNote: !getIsXapCurrent(account) && !getIsXapExitInProgress(account),
    selectedAccountAlias: accounts.find(acct => acct.isSelectedAccount)?.alias,
    serviceAddress,
    // we already got deviceFingerprintId from the CPC form submission
    hasDeviceFingerprintId: enhancedTransactionData?.deviceFingerprintId,
    transactionMetadata,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleGetAutopay: getAutopay,
  handlePaymentSubmit: submitPayment,
  handleSetSubmit: setSubmit,
  handleSetSuccess: setSuccess,
  handleSetError: setError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReview);
